import request from "../utils/request";

// 获取用户列表
export function queryUserPage(params) {
    return request({
        url: "/vc-user",
        method: 'GET',
        params
    })
}

// 添加后台管理人员
export function addUser(params) {
    return request({
        url: "/vc-user",
        method: 'POST',
        data: params
    })
}

// 删除人员
export function delUser(id) {
    return request({
        url: "/vc-user/state/" + id,
        method: 'DELETE'
    })
}

// 编辑人员
export function editUserRequest(params) {
    return request({
        url: "/vc-user",
        method: "PUT",
        data: params
    })
}
