<template>
  <div>
    <!--搜索-->
    <el-row>
      <el-col :span="24">
        <div class="search">
          <el-form :inline="true" :model="queryInfo">
            <el-form-item class="text" label="用户姓名">
              <el-input
                  placeholder="请输入用户姓名"
                  v-model="queryInfo.name"
                  size="small"
                  clearable>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" size="mini" type="primary" @click="refresh()">搜索</el-button>
              <el-button icon="el-icon-refresh" size="mini" @click="onReset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
    <!--表格-->
    <el-row>
      <el-col :span="24">
        <div style="width: auto;">
          <el-table
              :data="tableData"
              stripe
              style="width: 100%;">
            <el-table-column
                type="selection"
                align="center"
                width="110">
            </el-table-column>
            <el-table-column
                prop="id"
                label="用户编号"
                width="250">
            </el-table-column>
            <el-table-column
                prop="name"
                label="用户姓名"
                width="250">
            </el-table-column>
              <el-table-column
                      prop="mobilePhone"
                      label="用户手机号"
                      width="250">
              </el-table-column>
            <el-table-column
                prop="vicId"
                label="是否激活">
                <template slot-scope="scope">
                    <span v-if="scope.row.vicId != undefined">已激活</span>
                    <span v-if="scope.row.vicId == undefined">未激活</span>
                </template>
            </el-table-column>
                <el-table-column
                  prop="expireTime"
                  label="到期时间">
                  <template slot-scope="scope">
                    <span v-if="scope.row.expireTime != undefined">{{scope.row.expireTime}}</span>
                    <span v-if="scope.row.expireTime == undefined && scope.row.vicId != undefined">永久使用</span>
                </template>
              </el-table-column>
              <el-table-column
                      prop="createTime"
                      label="创建时间">
              </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.$index, scope.row)">禁用
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 15px;text-align: right;">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.page"
                :page-sizes="[10,20]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { queryUserPage, delUser } from '@/api/user.js'

export default {
  // name: "Home",
  data() {
    return {
      tableData: [],
      queryInfo: {
        name: "",
        //当前的页数
        page: 1,
        //当前每页显示的多少条数据
        pageSize: 10,
        type: 0
      },
      total: 0,
      addDialogVisible: false,
      editDialogVisible: false
    }
  },
  created () {
      this.refresh();
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    onReset() {
      this.queryInfo.name = "";
      this.refresh();
    },
    handleAdd(){
      this.addDialogVisible=true;
    },
    refresh() {
      queryUserPage(this.queryInfo).then((res) => {
          this.tableData = res.data.records
          this.total = res.data.total
          this.queryInfo.page = res.data.current
          this.queryInfo.pageSize = res.data.size
      })
    },
    handleEdit(index, row) {
      this.editDialogVisible=true;
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('是否确认禁用用户姓名为"' + row.name + '"的数据项??', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delUser(row.id).then((res) => {
          if (res.code == 0) {
            this.$message.success("禁用成功!")
          } else {
            this.$message.error(row.msg)
          }
        })
        this.refresh();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
      this.refresh();
    },
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.refresh()
    },
    handleCurrentChange(val) {
      this.queryInfo.page = val
      this.refresh()
    },
  }
}
</script>
<!--公共样式-->
<style scoped lang="scss">

</style>
